import Modal from './modules/modal'
import Cookies from 'js-cookie'
import FormErrors from './modules/formErrors'
import $Ajax from './modules/ajax'
import Register from 'js/pages/Index/register'
require('./modules/security');

/*********************************************************************
 **
 * Privacy Modal
 *
 ********************************************************************/
(() => {
  if (!document.getElementById('js-personal_data')) return

  const personalLink = document.getElementById('js-personal_data')
  const docModal = document.getElementById('docs_modal')

  personalLink.addEventListener('click', onLoginTriggerEvent)

  const modal = new Modal(docModal, {
    onInit: onModalInit

  })

  function onLoginTriggerEvent (e) {
    modal.show()
  }

  function onModalInit () { }
})();

/*********************************************************************
 **
 * Cookie
 *
 ********************************************************************/
(() => {
  if (!document.getElementById('js-cookie-alert')) return

  const cookieAlert = document.getElementById('js-cookie-alert')
  const cookieBtnAccept = cookieAlert.getElementsByClassName('cookie-alert__accept')[0]

  const modal = new Modal(cookieAlert, {
    modal: true
  })
  modal.show()

  cookieBtnAccept.addEventListener('click', onBtnAcceptClick)

  function onBtnAcceptClick (e) {
    e.preventDefault()
    Cookies.set('cookies_accepted', true, { expires: 365 })
    if (cookieExist) {
      modal.close()
    }
  }

  function cookieExist () {
    const cookiesExist = Cookies.get('cookies_accepted')
    return typeof cookiesExist !== 'undefined'
  }
})();

/*********************************************************************
 **
 * Burger menu
 *
 ********************************************************************/
(() => {
  const burgerMenu = document.getElementById('main-menu-burger')
  const mobileMenu = document.getElementById('menu-mobile')
  const mobileSubmenus = mobileMenu.getElementsByClassName('has-submenu')
  const body = document.body
  const pageWrap = document.getElementById('page-wrap')
  burgerMenu.addEventListener('click', onMenuClick)
  pageWrap.addEventListener('click', onPageWrapClick)

  subMenusEvents()

  function onMenuClick (e) {
    menuToggle()
  }

  function menuToggle () {
    burgerMenu.classList.toggle('burger--open')
    bodyScrollToggle()
    overlayToggle()
  }

  function subMenusEvents () {
    for (let i = 0; i < mobileSubmenus.length; i++) {
      const child = mobileSubmenus[i]
      const childLink = child.getElementsByClassName('menu__link')[0]
      const mobileSubmenusBackward = child.getElementsByClassName('menu-mobil__dropdown-header')[0]
      childLink.addEventListener('click', onChildClick)
      mobileSubmenusBackward.addEventListener('click', (e) => {
        onBackwardClick(e, child)
      })
    }
  }

  function onChildClick (e) {
    e.preventDefault()
    const target = e.target
    if (!target.closest('.menu__item')) return
    const item = target.closest('.menu__item')
    item.classList.toggle('submenu-opened')
  }

  function onBackwardClick (e, parentChild) {
    const target = e.target
    if (!target.classList.contains('menu-mobil__dropdown-btn-return')) return
    parentChild.classList.remove('submenu-opened')
  }

  function bodyScrollToggle () {
    body.classList.toggle('body--scroll-disabled')
  }

  function overlayToggle () {
    pageWrap.classList.toggle('page-wrap--overlay')
  }

  function onPageWrapClick (e) {
    const target = e.target
    if (!target.classList.contains('page-wrap')) return
    if (burgerMenu.classList.contains('burger--open')) {
      menuToggle()
    }
  }
})();

/*********************************************************************
 **
 * Search
 *
 ********************************************************************/
(() => {
  const body = document.body
  const searchForm = window.$summary.formSearch
  const searchButton = searchForm.getElementsByClassName('form-search__btn')[0]
  const searchButtonMobile = document.getElementById('search-mobile-btn')
  const mainMenu = document.getElementById('main-menu-desktop')
  const closeButton = searchForm.getElementsByClassName('form-search__btn-close')[0]
  const searchInput = document.getElementById(searchForm.getAttribute('name') + '_q')
  const searchResult = searchForm.getElementsByClassName('search-result')[0]
  const searchResultItemPrototype = searchResult.getElementsByClassName('search-result__item_prototype')[0]
  const ajax = new $Ajax()
  let timeout = null

  searchButtonMobile.addEventListener('click', onSearchButtonClick)
  searchButton.addEventListener('click', onSearchButtonClick)
  closeButton.addEventListener('click', onCloseButtonClick)
  searchInput.addEventListener('input', onInputChange)

  function onSearchButtonClick (e) {
    const target = e.target
    target.classList.toggle('form-search__btn--hide')
    mainMenu.classList.toggle('header__menu--active-search')
  }

  function onCloseButtonClick (e) {
    hideSearchResult()
    clearResult()
    searchButton.classList.remove('form-search__btn--hide')
    mainMenu.classList.remove('header__menu--active-search')
    bodyScrollDisable()
  }

  function onInputChange (e) {
    const target = e.target
    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      const countWords = countWord(target.value)
      if (countWords > 3) search(target.value)
    }, 200)
  }

  function search (query) {
    ajax.send({
      url: searchForm.getAttribute('action'),
      type: searchForm.getAttribute('method'),
      data: new FormData(searchForm),
      success: onSearchSuccess,
      error: onSearchError
    })
  }

  function onSearchSuccess (data) {
    if (window.innerWidth <= 992) {
      bodyScrollEnable()
    }
    const parsedData = JSON.parse(data).message
    clearResult()
    for (const i in parsedData) {
      const prototype = prototypeItems(parsedData[i])
      const newItem = document.createElement('div')
      newItem.classList.add('item')
      newItem.insertAdjacentHTML('beforeend', prototype)
      searchResult.appendChild(newItem)
    }
    const imgs = searchResult.getElementsByTagName('img')
    const links = searchResult.getElementsByTagName('a')
    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i]
      const link = links[i]
      img.setAttribute('src', img.getAttribute('data-src'))
      link.setAttribute('href', link.getAttribute('data-href'))
    }
    showSearchResult()
  }

  function onSearchError (response, errors) {
  }

  function clearResult () {
    searchResult.innerHTML = ''
  }

  function prototypeItems (data) {
    const prototypeText = searchResultItemPrototype.innerHTML

    const tokensTitle = prototypeText.split('[__book_title__]')
    const replacedTitle = tokensTitle.join(data.book.book_title)

    const tokensAuthor = replacedTitle.split('[__book_author__]')
    const replacedAuthor = tokensAuthor.join(data.book.book_author)

    const tokensCategory = replacedAuthor.split('[__book_category__]')
    const replacedCategory = tokensCategory.join(data.book.cat_title)

    const tokensImg = replacedCategory.split('[__book_poster__]')
    const replacedImg = tokensImg.join(data.book.book_poster)

    const tokensUrl = replacedImg.split('[__book_url__]')
    return tokensUrl.join(data.book.book_url)
  }

  function countWord (val) {
    const wom = val.match(/\S/g)
    return wom ? wom.length : 0
  }

  function showSearchResult () {
    searchResult.classList.add('search-result__visible')
  }

  function hideSearchResult () {
    searchResult.classList.remove('search-result__visible')
  }

  function bodyScrollDisable () {
    body.classList.remove('body--scroll-disabled')
  }

  function bodyScrollEnable () {
    body.classList.add('body--scroll-disabled')
  }
})();

/*********************************************************************
 **
 * Subscribe demo form
 *
 ********************************************************************/
(() => {
  if (!window.$summary.newsletterSubscribeDemoForm) return
  const form = window.$summary.newsletterSubscribeDemoForm
  const formSubmitBtn = document.getElementById(form.getAttribute('name') + '_submit')
  const modalWindow = document.getElementById('js-cookie-new')
  const modalClose = modalWindow.getElementsByClassName('close')[0]
  const overlay = document.getElementById('modal-overlay')
  const body = document.body
  const ajax = new $Ajax()

  setTimeout(() => {
    showModal()
  }, 7000)

  formSubmitBtn.addEventListener('click', onSubmitClick)
  modalClose.addEventListener('click', onCloseClick)
  overlay.addEventListener('modal:after_closed', onCloseClick)

  function bodyScrollToggle () {
    body.classList.toggle('body--scroll-disabled')
  }

  function bodyScrollEnable () {
    body.classList.remove('body--scroll-disabled')
  }

  function overlayShow () {
    const overlay = document.getElementById('modal-overlay')
    overlay.classList.toggle('visible')
    overlay.style.zIndex = '1000'
  }

  function overlayHide () {
    overlay.classList.remove('visible')
    overlay.style.zIndex = '-1'
  }

  function onSubmitClick (e) {
    e.preventDefault()
    ajax.send({
      url: form.getAttribute('action'),
      type: form.getAttribute('method'),
      data: new FormData(form),
      success: onSuccess,
      error: onError
    })
  }

  function onSuccess (data) {
    const parsedData = JSON.parse(data).message
    Cookies.set('client_new', false, { expires: 365 })
    window.location.href = window.$summary.registerLink + '?email=' + parsedData.email
  }

  function onError (response, errors) {
    const errorHandler = new FormErrors(form, errors, {
      formRowClass: 'form__group',
      formErrorClass: 'error-msg',
      rowErrorClass: 'has-error'
    })
    errorHandler.clear()
    errorHandler.parse()
  }

  function hideModal () {
    Cookies.set('client_new', false, { expires: 365 })
    modalWindow.classList.remove('visible')
  }

  function showModal () {
    bodyScrollToggle()
    overlayShow()
    modalWindow.classList.add('visible')
  }

  function onCloseClick () {
    overlayHide()
    hideModal()
    bodyScrollEnable()
  }
})();

// Try free
(() => {
  const isAuth = window.$summary.isAuth
  if (isAuth) return
  if (window.innerWidth > 768) return
  const tryFree = document.getElementById('try-free')
  const header = document.getElementById('header')
  const logo = header.getElementsByClassName('header__logo')[0]
  tryFree.addEventListener('click', onBtnClick)
  toggleVisible(window.scrollY)
  window.addEventListener('scroll', (e) => {
    toggleVisible(window.scrollY)
  })

  function toggleVisible (ypos) {
    if (header.getBoundingClientRect().height > ypos) {
      tryFree.classList.remove('visible')
      logo.classList.remove('mini')
      header.classList.remove('try-visible')
    } else {
      tryFree.classList.add('visible')
      logo.classList.add('mini')
      header.classList.add('try-visible')
    }
  }

  function onBtnClick (e) {
    e.preventDefault()
    window.$summary.registerModal.show()
  }
})();

/**
 * App links metrika
 */
(() => {
  const downloadsLinks = document.getElementsByClassName('app-links')
  if (downloadsLinks.length === 0) return
  for (let i = 0; i < downloadsLinks.length; i++) {
    downloadsLinks[i].addEventListener('click', onLinkClick)
  }

  function onLinkClick (e) {
    e.preventDefault()
    const link = e.target.closest('.app-link')
    if (!link) return
    if (link.classList.contains('app-link--ios')) {
      ym(56072866, 'reachGoal', 'download_ios')
    }
    if (link.classList.contains('app-link--google')) {
      ym(56072866, 'reachGoal', 'download_android')
    }
    window.location.href = link.getAttribute('href')
  }
})()
